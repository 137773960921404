@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    url('/fonts/FiraSans-Light.woff2') format('woff2'),
    url('/fonts/FiraSans-Light.woff') format('woff'),
    url('/fonts/FiraSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    url('/fonts/FiraSans-Regular.woff2') format('woff2'),
    url('/fonts/FiraSans-Regular.woff') format('woff'),
    url('/fonts/FiraSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    url('/fonts/FiraSans-Medium.woff2') format('woff2'),
    url('/fonts/FiraSans-Medium.woff') format('woff'),
    url('/fonts/FiraSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    url('/fonts/FiraSans-SemiBold.woff2') format('woff2'),
    url('/fonts/FiraSans-SemiBold.woff') format('woff'),
    url('/fonts/FiraSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    url('/fonts/FiraSans-Bold.woff2') format('woff2'),
    url('/fonts/FiraSans-Bold.woff') format('woff'),
    url('/fonts/FiraSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src:
    url('/fonts/FiraSans-ExtraBold.woff2') format('woff2'),
    url('/fonts/FiraSans-ExtraBold.woff') format('woff'),
    url('/fonts/FiraSans-ExtraBold.ttf') format('truetype');
}
